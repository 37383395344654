import useEventListener from 'hooks/useEventListener'
import { useEffect, useRef, useState } from 'react'
import { SwipeDirections } from 'react-swipeable'
import { TCarouselOptions } from '../types'

type TProps = {
  itemsLength: number
  slidesPerPage: number
  slidesInfinite: boolean
  slidesGap: number
  handleResize: () => void
  transition?: TCarouselOptions['transition']
  startIndex?: number
  onSlideChange?: (slideIndex: number) => void
}

const useCarousel = ({
  itemsLength,
  slidesPerPage,
  slidesInfinite,
  slidesGap,
  handleResize,
  transition,
  startIndex,
  onSlideChange,
}: TProps) => {
  const slideStartIndex = startIndex ?? Math.floor(slidesPerPage) - 1
  const slideEndIndex = itemsLength - 1
  const slidesTotal = slideEndIndex - slideStartIndex

  const [currentItem, setCurrentItem] = useState(slideStartIndex)
  const [currentShift, setCurrentShift] = useState(0)

  const isSlideEnd = currentItem === slideEndIndex
  const isSlideStart = currentItem === slideStartIndex

  const carouselRef = useRef<HTMLDivElement>(null)
  const itemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setCurrentItem(slideStartIndex)
  }, [slideStartIndex])

  const getCurrentItemWidth = () =>
    itemRef.current
      ? itemRef.current.getBoundingClientRect().width + slidesGap
      : 0

  const getCarouselWidth = () =>
    carouselRef.current ? carouselRef.current.getBoundingClientRect().width : 0

  const updateCarousel = (position: number, shift: number) => {
    const content = carouselRef.current
    /* istanbul ignore next */
    if (!content) return

    content.style.transition = transition
      ? `left ${transition?.duration} ${transition?.timingFunction}`
      : 'left 0.4s ease'
    content.style.left = `${shift}px`
    setCurrentShift(shift)
    setCurrentItem(position)
    if (onSlideChange) onSlideChange(position)
  }

  const swipingTo = (direction: SwipeDirections, deltaX: number) => {
    if (!slidesInfinite && (isSlideStart || isSlideEnd)) return

    const content = carouselRef.current
    /* istanbul ignore next */
    if (!content) return

    if (direction === 'Left' || direction === 'Right') {
      content.style.transition = 'unset'
      content.style.left = `${currentShift + deltaX}px`
    }
  }

  const swipeTo = ({
    direction,
    slideToNext,
    slideToPrev,
  }: {
    direction: SwipeDirections
    slideToNext: () => void
    slideToPrev: () => void
  }) => {
    if (direction === 'Up' || direction === 'Down') return
    if (direction === 'Left') slideToNext()
    if (direction === 'Right') slideToPrev()
  }

  useEventListener({
    type: 'resize',
    listener: handleResize,
  })

  return {
    slideStartIndex,
    slideEndIndex,
    slidesTotal,
    currentItem,
    currentShift,
    isSlideEnd,
    isSlideStart,
    carouselRef,
    itemRef,
    getCurrentItemWidth,
    swipingTo,
    swipeTo,
    updateCarousel,
    setCurrentShift,
    getCarouselWidth,
  }
}

export default useCarousel
