import { FC } from 'react'
import { TCarouselOptions, TCompressedVariantOptions, TVariants } from './types'
import Default from './components/variants/Default'
import Compressed from './components/variants/Compressed'

export type TProps = TCarouselOptions & TVariants

const Carousel: FC<TProps> = ({
  items,
  variant = 'default',
  compressedVariantOptions,
  ...props
}) => {
  const CarouselVariantComponent = {
    default: <Default items={items} {...props} />,
    compressed: (
      <Compressed
        items={items}
        compressedVariantOptions={
          compressedVariantOptions as TCompressedVariantOptions
        }
        {...props}
      />
    ),
  }[variant]

  if (items?.length === 0) return null

  return CarouselVariantComponent
}

export default Carousel
