import { CSSProperties, FC, HTMLAttributes, MouseEvent } from 'react'
import { IconChevronLeft, IconChevronRight } from 'icons'
import classNames from 'classnames'
import Button from 'components/Button'
import styles from './CarouselNavigation.module.scss'
import { TCarouselNavigation } from '../types'

export type TProps = {
  onClickNext: () => void
  onClickPrev: () => void
  nextDisabled?: boolean
  prevDisabled?: boolean
  elementProps?: HTMLAttributes<HTMLButtonElement>
} & TCarouselNavigation

const CarouselNavigation: FC<TProps> = ({
  variant = 'inline',
  theme = 'outline',
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrev,
  elementProps,
  verticalPosition = 'top',
  customPosition,
}) => {
  const variantMap = {
    inline: {
      className: null,
      iconColor: 'grey900',
    },
    inside: {
      className: styles.ecCarouselNavigationVariantInside,
      iconColor: theme === 'outline' ? 'grey900' : 'white',
    },
    outside: {
      className: styles.ecCarouselNavigationVariantOutside,
      iconColor: 'grey900',
    },
  }

  const verticalPositionMap = {
    top: {
      className: null,
    },
    bottom: {
      className: styles.ecCarouselNavigationBottom,
    },
  }

  return (
    <div
      className={classNames(
        elementProps?.className,
        styles.ecCarouselNavigation,
        variantMap[variant].className,
        verticalPositionMap[verticalPosition].className,
      )}
      style={
        {
          '--left': customPosition?.left,
          '--right': customPosition?.left,
        } as CSSProperties
      }
    >
      <Button
        aria-label="Anterior"
        theme={theme}
        shape="circle"
        size="small"
        disabled={prevDisabled}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          onClickPrev()
        }}
        className={styles.ecCarouselNavigationPrev}
        Icon={{
          Component: IconChevronLeft,
          color: variantMap[variant].iconColor,
          title: 'Anterior',
        }}
      />
      <Button
        aria-label="Próximo"
        theme={theme}
        shape="circle"
        size="small"
        disabled={nextDisabled}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          onClickNext()
        }}
        className={styles.ecCarouselNavigationNext}
        Icon={{
          Component: IconChevronRight,
          color: variantMap[variant].iconColor,
          title: 'Próximo',
        }}
      />
    </div>
  )
}

export default CarouselNavigation
