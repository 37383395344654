import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { TCarouselTemplate } from 'components/Carousel/types'
import CarouselNavigation from '../../CarouselNavigation'
import CarouselPagination from '../../CarouselPagination'
import { useSwipeable } from 'react-swipeable'
import styles from './Default.module.scss'

type TProps = TCarouselTemplate

const Default: FC<PropsWithChildren<TProps>> = ({
  children,
  navigation,
  pagination,
  staticNavigation,
  swipingTo,
  swipeTo,
  trackMouse,
  trackTouch,
  onPagination,
  slidesInfinite,
  slideToNext,
  slideToPrev,
  isSlideEnd,
  isSlideStart,
  currentItem,
  slideEndIndex,
  slideStartIndex,
  slideTo,
}) => {
  const variantNavigationMap = {
    inline: {
      className: null,
    },
    inside: {
      className: null,
    },
    outside: {
      className: styles.ecCarouselNavigationVariantOutsideContainer,
    },
  }

  const verticalPositionMap = {
    top: {
      className: null,
    },
    bottom: {
      className: styles.ecCarouselNavigationBottom,
    },
  }

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation()
      swipeTo({ direction: eventData.dir, slideToNext, slideToPrev })
    },
    onSwiping: (eventData) => {
      eventData.event.stopPropagation()
      swipingTo(eventData.dir, eventData.deltaX)
    },
    trackTouch: !staticNavigation && trackTouch,
    trackMouse: !staticNavigation && trackMouse,
  })

  return (
    <div
      className={classNames(
        styles.ecCarouselContainer,
        variantNavigationMap[navigation?.variant ?? 'inline'].className,
      )}
    >
      <div
        data-testid="carousel"
        className={classNames(
          styles.ecCarousel,
          verticalPositionMap[navigation?.verticalPosition || 'top'].className,
        )}
      >
        <div
          className={styles.ecCarouselContent}
          {...(staticNavigation ? {} : { ...swipeHandlers })}
        >
          {children}
        </div>

        {!navigation?.hide && !staticNavigation && (
          <CarouselNavigation
            variant={navigation?.variant}
            verticalPosition={navigation?.verticalPosition}
            theme={navigation?.theme}
            nextDisabled={!slidesInfinite && isSlideEnd}
            prevDisabled={!slidesInfinite && isSlideStart}
            onClickNext={slideToNext}
            onClickPrev={slideToPrev}
            elementProps={{ className: styles.ecCarouselNavigation }}
            customPosition={navigation?.customPosition}
          />
        )}
      </div>

      {!pagination?.hide && !staticNavigation && (
        <CarouselPagination
          variant={pagination?.variant}
          label={pagination?.label}
          currentItem={currentItem}
          slideStartIndex={slideStartIndex}
          slideEndIndex={slideEndIndex}
          onClickItem={(item: number) => {
            if (onPagination) onPagination(item)
            slideTo(item)
          }}
          elementProps={{ className: styles.ecCarouselPagination }}
        />
      )}
    </div>
  )
}

export default Default
