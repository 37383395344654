import {
  CSSProperties,
  FC,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import classNames from 'classnames'
import useCarousel from 'components/Carousel/hook/useCarousel'
import {
  TCompressedVariantOptions,
  TCarouselOptions,
} from 'components/Carousel/types'
import styles from './Compressed.module.scss'
import DefaultCarouselTemplate from '../../templates/Default'

export type TProps = {
  compressedVariantOptions: TCompressedVariantOptions
} & TCarouselOptions

export const CompressedCarouselContext = createContext<{
  slideTo?: (position: number) => void
}>({})

const Compressed: FC<TProps> = ({
  items,
  staticNavigation = false,
  slidesInfinite = true,
  slidesPerPage = 5,
  slidesGap = 0,
  navigation,
  pagination,
  onSlideToNext,
  onSlideToPrev,
  onPagination,
  trackTouch = false,
  trackMouse = false,
  compressedVariantOptions,
  transition,
  onSlideChange,
  resetOnItemsChange = true,
}) => {
  const [compressedItemWidth, setCompressedItemWidth] = useState(0)

  const compressedItemsCount = slidesPerPage - 1

  const halfOfCompressedItems = Math.ceil(compressedItemsCount / 2)

  /* istanbul ignore next */
  const fixShift = () => {
    const content = carouselRef.current
    if (!content) return

    content.style.transition = 'none'

    setCompressedItemWidth(getCompressedItemWidth())

    if (
      currentItem === slideStartIndex ||
      currentItem <= halfOfCompressedItems
    ) {
      content.style.left = '0px'
      setCurrentShift(0)
      return
    }

    const totalSlidesScroll =
      currentItem >= slidesCount - halfOfCompressedItems
        ? slidesTotal - compressedItemsCount
        : currentItem - halfOfCompressedItems

    const shift = -(getCompressedItemWidth() * totalSlidesScroll)
    content.style.left = `${shift}px`
    setCurrentShift(shift)
  }

  const {
    carouselRef,
    currentItem,
    currentShift,
    isSlideEnd,
    isSlideStart,
    itemRef,
    slideEndIndex,
    slideStartIndex,
    slidesTotal,
    swipeTo,
    swipingTo,
    updateCarousel,
    setCurrentShift,
    getCarouselWidth,
  } = useCarousel({
    itemsLength: items.length,
    slidesGap,
    slidesInfinite,
    slidesPerPage,
    handleResize: fixShift,
    transition,
    startIndex: 0,
    onSlideChange,
  })

  const slidesCount = slidesTotal + 1

  const getCompressedItemWidth = useCallback(() => {
    const hasCompressedMaxWidth =
      !!compressedVariantOptions?.compressedItemMaxWidth

    const compressedItemMaxWidthWithGap =
      (compressedVariantOptions?.compressedItemMaxWidth || 0) + slidesGap

    const divide =
      slidesCount <= compressedItemsCount
        ? slidesCount - 1
        : compressedItemsCount

    const itemWidth = Math.min(
      (getCarouselWidth() - compressedVariantOptions?.activeItemWidth) / divide,
      hasCompressedMaxWidth ? compressedItemMaxWidthWithGap : Infinity,
    )

    return itemWidth
  }, [
    compressedItemsCount,
    compressedVariantOptions?.activeItemWidth,
    compressedVariantOptions?.compressedItemMaxWidth,
    getCarouselWidth,
    slidesCount,
    slidesGap,
  ])

  const compressedWidth = getCompressedItemWidth()

  const onChangeItemsLength = () => {
    if (currentItem !== 0) slideToPrev()
  }

  useEffect(() => {
    setCompressedItemWidth(getCompressedItemWidth())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compressedWidth])

  useEffect(() => {
    if (!resetOnItemsChange) onChangeItemsLength()
    else slideTo(slideStartIndex)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length])

  const slideToEnd = () => {
    if (!slidesInfinite) return

    const shiftValue =
      getCompressedItemWidth() * (slidesCount - compressedItemsCount - 1)

    const shift = shiftValue < 0 ? 0 : -shiftValue

    updateCarousel(slideEndIndex, shift)
  }

  const slideToStart = () => {
    if (!slidesInfinite) return

    updateCarousel(slideStartIndex, 0)
  }

  const slideToNext = () => {
    if (isSlideEnd) {
      slideToStart()
      return
    }

    const newCurrentItem = currentItem + 1

    const isLastPage =
      compressedItemsCount % 2 !== 0
        ? newCurrentItem > slidesCount - halfOfCompressedItems
        : newCurrentItem >= slidesCount - halfOfCompressedItems

    const shift =
      newCurrentItem <= halfOfCompressedItems || isLastPage
        ? currentShift
        : currentShift - getCompressedItemWidth()

    updateCarousel(newCurrentItem, shift)
    if (onSlideToNext) onSlideToNext(newCurrentItem)
  }

  const slideToPrev = () => {
    if (isSlideStart) {
      slideToEnd()
      return
    }

    const newCurrentItem = currentItem - 1

    const firstPage =
      compressedItemsCount % 2 !== 0
        ? newCurrentItem > slidesTotal - halfOfCompressedItems
        : newCurrentItem >= slidesTotal - halfOfCompressedItems

    const shift =
      newCurrentItem < halfOfCompressedItems || firstPage
        ? currentShift
        : currentShift + getCompressedItemWidth()

    updateCarousel(newCurrentItem, shift)
    if (onSlideToPrev) onSlideToPrev(newCurrentItem)
  }

  const slideTo = (position: number) => {
    if (position === slideStartIndex) {
      updateCarousel(slideStartIndex, 0)
      return
    }

    const rest = compressedItemsCount % 2 !== 0 ? 1 : 0

    const minScrollPositionIndex = Math.abs(compressedItemsCount - 1)
    const maxScrollPositionIndex = Math.abs(
      slidesTotal - halfOfCompressedItems + rest,
    )

    const totalSlidesScroll = Math.max(
      maxScrollPositionIndex - halfOfCompressedItems,
      0,
    )

    if (position < minScrollPositionIndex) {
      updateCarousel(position, 0)
      return
    }

    if (position >= maxScrollPositionIndex) {
      updateCarousel(position, -(getCompressedItemWidth() * totalSlidesScroll))
      return
    }

    const shift = -(
      (position - minScrollPositionIndex + 1) *
      getCompressedItemWidth()
    )

    updateCarousel(position, shift)
  }

  return (
    <CompressedCarouselContext.Provider value={{ slideTo }}>
      <DefaultCarouselTemplate
        currentItem={currentItem}
        isSlideEnd={isSlideEnd}
        isSlideStart={isSlideStart}
        slideEndIndex={slideEndIndex}
        slideStartIndex={slideStartIndex}
        slideTo={slideTo}
        slideToNext={slideToNext}
        slideToPrev={slideToPrev}
        swipeTo={swipeTo}
        swipingTo={swipingTo}
        navigation={navigation}
        onPagination={onPagination}
        pagination={pagination}
        slidesInfinite={slidesInfinite}
        trackMouse={trackMouse}
        trackTouch={trackTouch}
        staticNavigation={staticNavigation}
      >
        <div
          data-testid="compressed-carousel-content"
          data-current-item={currentItem}
          ref={carouselRef}
          className={styles.ecCarouselItems}
          style={
            {
              '--carouselSlidesGap': `${slidesGap}px`,
              '--activeItemWidth': `${compressedVariantOptions.activeItemWidth}px`,
              '--compressedItemWidth': `${compressedItemWidth}px`,
              '--compressedItemMaxWidth': `${
                compressedVariantOptions.compressedItemMaxWidth
                  ? `${compressedVariantOptions.compressedItemMaxWidth}px`
                  : '100%'
              }`,
              '--widthTransition': `${
                transition
                  ? `all ${transition.duration} ${transition.timingFunction}`
                  : 'all 0.4s ease'
              }`,
            } as CSSProperties
          }
        >
          {items.map((item, index) => {
            const carouselItemKey = `carouselItem_${index}`
            const currentRef = index === currentItem ? itemRef : null
            const isActive = index === currentItem

            return (
              <div
                key={carouselItemKey}
                ref={currentRef}
                className={classNames(styles.ecCarouselItem, {
                  [styles.active]: isActive,
                  [styles.inactive]: !isActive,
                })}
                onClick={() => {
                  if (!isActive) slideTo(index)
                }}
              >
                {item}
              </div>
            )
          })}
        </div>
      </DefaultCarouselTemplate>
    </CompressedCarouselContext.Provider>
  )
}

export default Compressed
