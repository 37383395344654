import { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'
import styles from './CarouselPagination.module.scss'
import { TCarouselPagination } from '../types'

export type TProps = {
  currentItem: number
  slideStartIndex: number
  slideEndIndex: number
  onClickItem: (item: number) => void
  elementProps?: HTMLAttributes<HTMLButtonElement>
} & TCarouselPagination

const CarouselPagination: FC<TProps> = ({
  variant = 'inline',
  label,
  currentItem,
  slideStartIndex,
  slideEndIndex,
  onClickItem,
  elementProps,
}) => {
  const slideTotal = slideEndIndex - slideStartIndex + 1 // include current item

  const items = Array.from(
    { length: slideTotal },
    (_, index) => index + slideStartIndex,
  )

  const variantMap = {
    inline: {
      className: null,
    },
    inside: {
      className: styles.ecCarouselPaginationVariantInside,
    },
  }

  return (
    <div
      className={classNames(
        elementProps?.className,
        styles.ecCarouselPagination,
        variantMap[variant].className,
      )}
    >
      {label && <p className={styles.ecCarouselPaginationLabel}>{label}</p>}
      <ul className={classNames(styles.ecCarouselPaginationList)}>
        {items.map((item, index) => {
          const carouselPaginationKey = `carouselPagination_${index}`

          return (
            <li key={carouselPaginationKey}>
              <button
                type="button"
                aria-label={`Ir para slide ${index + 1}`}
                disabled={currentItem === item}
                onClick={() => onClickItem(item)}
                className={styles.ecCarouselPaginationButton}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CarouselPagination
