import { useEffect, useState } from 'react'

const useMediaQuery = (raw: string): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const query = matchMedia(raw)
    setMatches(query.matches)

    const handleChange = (e: MediaQueryListEvent): void => {
      setMatches(e.matches)
    }

    if (query.addListener) {
      query.addListener(handleChange)
    } else {
      query.addEventListener('change', handleChange)
    }

    return (): void => {
      if (query.removeListener) {
        query.removeListener(handleChange)
      } else {
        query.removeEventListener('change', handleChange)
      }
    }
  }, [raw])

  return matches
}

export default useMediaQuery
