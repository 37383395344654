import { CSSProperties, FC, useEffect, createContext, useState } from 'react'
import useCarousel from 'components/Carousel/hook/useCarousel'
import { TCarouselOptions } from 'components/Carousel/types'
import styles from './Default.module.scss'
import DefaultCarouselTemplate from '../../templates/Default'

export type TProps = TCarouselOptions

export const DefaultCarouselContext = createContext<{
  slideTo?: (position: number) => void
}>({})

const Default: FC<TProps> = ({
  items,
  staticNavigation = false,
  slidesInfinite = true,
  slidesPerPage = 1,
  slidesGap = 0,
  navigation,
  pagination,
  onSlideToNext,
  onSlideToPrev,
  onPagination,
  trackTouch = true,
  trackMouse = true,
  centerMode,
  onSlideChange,
  resetOnItemsChange = true,
}) => {
  /* istanbul ignore next */
  const handleResize = () => {
    setItemWidth(getCurrentItemWidth())
    if (currentItem !== slideStartIndex) fixShift()
  }

  const {
    carouselRef,
    currentItem,
    currentShift,
    getCurrentItemWidth,
    isSlideEnd,
    isSlideStart,
    itemRef,
    slideEndIndex,
    slideStartIndex,
    slidesTotal,
    swipeTo,
    swipingTo,
    updateCarousel,
    setCurrentShift,
  } = useCarousel({
    itemsLength: items.length,
    slidesGap,
    slidesInfinite,
    slidesPerPage,
    handleResize,
    onSlideChange,
  })

  const [itemWidth, setItemWidth] = useState(0)

  const currentItemPercentage = 100 / slidesPerPage
  const halfVisibleItemPercentage =
    100 - currentItemPercentage * Math.floor(slidesPerPage)

  const halfVisibleItemWidth =
    halfVisibleItemPercentage * (itemWidth / currentItemPercentage)

  const hasMinimunItemsQuantity = items.length >= 3

  const divide = hasMinimunItemsQuantity ? 2 : 1

  const getCenterShift = ({
    newCurrentItem,
    onItemsChange,
  }: {
    newCurrentItem: number
    onItemsChange?: boolean
  }) => {
    const isFirstPage = currentShift === 0

    const lastItem =
      Math.floor(slidesPerPage) - 1 + items.length - 1 - slideStartIndex

    const isLastPage = newCurrentItem === lastItem

    const isNextToLastItem =
      newCurrentItem === lastItem - 1 &&
      currentItem === lastItem &&
      !onItemsChange

    if (isFirstPage || isLastPage || isNextToLastItem) {
      return itemWidth - halfVisibleItemWidth / divide
    }

    return itemWidth
  }

  /* istanbul ignore next */
  const fixShift = () => {
    const content = carouselRef.current
    if (!content) return

    content.style.transition = 'none'

    if (currentItem === slideStartIndex) {
      content.style.left = '0px'
      setCurrentShift(0)
      return
    }

    const isSlideEnd = currentItem === slideEndIndex

    const rest = isSlideEnd ? halfVisibleItemWidth : 0

    const getShift = () => {
      if (centerMode && !isSlideStart && !isSlideEnd) {
        return -(
          getCurrentItemWidth() * totalToFixed -
          halfVisibleItemWidth / 2
        )
      }

      return -(getCurrentItemWidth() * totalToFixed - rest)
    }

    const totalToFixed = currentItem - slideStartIndex
    const shift = getShift()
    content.style.left = `${shift}px`
    setCurrentShift(shift)
  }

  const onChangeItemsLength = () => {
    if (currentItem !== 0) slideToPrev({ onItemsChange: true })
  }

  useEffect(() => {
    if (!resetOnItemsChange) onChangeItemsLength()
    else slideTo(slideStartIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length])

  useEffect(() => {
    setItemWidth(getCurrentItemWidth())
  }, [getCurrentItemWidth])

  const slideToEnd = () => {
    if (!slidesInfinite) return

    const shift = -(getCurrentItemWidth() * slidesTotal - halfVisibleItemWidth)
    updateCarousel(slideEndIndex, shift)
  }

  const slideToStart = () => {
    if (!slidesInfinite) return

    updateCarousel(slideStartIndex, 0)
  }

  const slideToNext = () => {
    if (isSlideEnd) {
      slideToStart()
      return
    }

    const newCurrentItem = currentItem + 1

    const getShiftValue = () => {
      if (centerMode) return currentShift - getCenterShift({ newCurrentItem })

      if (newCurrentItem === slideEndIndex) {
        return currentShift - getCurrentItemWidth() + halfVisibleItemWidth
      }

      return currentShift - getCurrentItemWidth()
    }

    const shift = getShiftValue()
    updateCarousel(newCurrentItem, shift)
    if (onSlideToNext) onSlideToNext(newCurrentItem)
  }

  const slideToPrev = ({ onItemsChange }: { onItemsChange?: boolean } = {}) => {
    if (isSlideStart) {
      slideToEnd()
      return
    }

    const isLastChanged = onItemsChange && items.length === currentItem

    const newCurrentItem = currentItem - 1
    const isNextToLastItem = newCurrentItem === slideEndIndex - 1

    const getShiftValue = () => {
      if (centerMode)
        return Math.min(
          currentShift +
            getCenterShift({
              newCurrentItem: isLastChanged ? currentItem : newCurrentItem,
              onItemsChange,
            }),
          0,
        )

      if (isNextToLastItem) {
        return currentShift + itemWidth - halfVisibleItemWidth
      }

      return currentShift + itemWidth
    }

    const shift = getShiftValue()
    updateCarousel(newCurrentItem, shift)
    if (onSlideToPrev) onSlideToPrev(newCurrentItem)
  }

  const slideTo = (position: number) => {
    if (position === slideStartIndex) {
      updateCarousel(slideStartIndex, 0)
      return
    }

    const totalToSlide = position - currentItem
    const current = currentItem - Math.floor(slidesPerPage) + 1
    const isCurrentLastPage = currentItem === slideEndIndex
    const isFirstPosition = position === 0
    const isLastPostion = position === slideEndIndex
    const isInTheMiddle = !isFirstPosition && !isLastPostion
    const isCurrentFirstOrLast = current === 0 || current === slideEndIndex

    const divide = centerMode ? 2 : 1

    const getShift = () => {
      if (isCurrentLastPage) {
        return (
          currentShift -
          halfVisibleItemWidth / divide -
          getCurrentItemWidth() * totalToSlide
        )
      }

      if (
        centerMode &&
        ((isCurrentFirstOrLast && isInTheMiddle) ||
          (isLastPostion && current !== 0 && current !== slideEndIndex))
      ) {
        return (
          currentShift +
          halfVisibleItemWidth / 2 -
          getCurrentItemWidth() * totalToSlide
        )
      }

      if (position === slideEndIndex) {
        return (
          currentShift +
          halfVisibleItemWidth -
          getCurrentItemWidth() * totalToSlide
        )
      }

      return currentShift - getCurrentItemWidth() * totalToSlide
    }

    const shift = getShift()
    updateCarousel(position, shift)
  }

  return (
    <DefaultCarouselContext.Provider value={{ slideTo }}>
      <DefaultCarouselTemplate
        currentItem={currentItem}
        isSlideEnd={isSlideEnd}
        isSlideStart={isSlideStart}
        slideEndIndex={slideEndIndex}
        slideStartIndex={slideStartIndex}
        slideTo={slideTo}
        slideToNext={slideToNext}
        slideToPrev={slideToPrev}
        swipeTo={swipeTo}
        swipingTo={swipingTo}
        navigation={navigation}
        onPagination={onPagination}
        pagination={pagination}
        slidesInfinite={slidesInfinite}
        trackMouse={trackMouse}
        trackTouch={trackTouch}
        staticNavigation={staticNavigation}
      >
        <div
          data-testid="default-carousel-content"
          data-current-item={currentItem}
          ref={carouselRef}
          className={styles.ecCarouselItems}
          style={
            {
              '--carouselSlidesGap': `${slidesGap}px`,
              '--carouselItemQuantity': items.length,
              '--carouselItemWidth': `${100 / slidesPerPage}%`,
              '--carouselItemGapDiff': `${
                slidesGap / slidesPerPage - slidesGap
              }px`,
            } as CSSProperties
          }
        >
          {items.map((item, index) => {
            const carouselItemKey = `carouselItem_${index}`
            const currentRef = index === currentItem ? itemRef : null

            return (
              <div
                key={carouselItemKey}
                ref={currentRef}
                className={styles.ecCarouselItem}
              >
                {item}
              </div>
            )
          })}
        </div>
      </DefaultCarouselTemplate>
    </DefaultCarouselContext.Provider>
  )
}

export default Default
